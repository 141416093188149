<template>
  <div>
    <br />
    <div class="row row-sm">
      <div class="col-12 col-md-6 col-lg-3 col-xl-3 my-3">
        <label for=""> Client </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col-12 col-md-6 col-lg col-xl my-3">
        <label for=""> Du </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>

      <div class="col-12 col-md-6 col-lg col-xl my-3">
        <label for=""> Au </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-12 col-md-6 col-lg-2 col-xl-2 my-3">
        <label for=""> Statut </label>
        <select v-model="filter.status" class="form-select">
          <option value="--">--</option>
          <option value="in_progress">En cours</option>
          <option value="ends">Finit</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-xl-auto col-lg-auto col-md-auto col-auto">
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary mx-2">
          <i class="bi bi-funnel"></i>
          {{ $t("sale11") }}
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-auto">
        <button @click="refresh()" class="btn btn-secondary mx-2">
          <i class="bi bi-arrow-clockwise"></i>
          {{ $t("sale12") }}
        </button>
      </div>

      <div
        class="col-xl-auto col-lg-auto col-md-auto col-auto d-xl-inline d-lg-inline d-md-inline d-none"
      >
        <button
          @click="$router.push({ name: 'repairs-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un réparation
        </button>
      </div>
    </div>
    <br />
    <table
      class="table d-xl-table d-lg-table d-md-table d-none table-striped table-hover"
    >
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Reference</th>
          <th scope="col">Client</th>
          <th scope="col">Date</th>
          <th scope="col" class="text-end">Montant Total</th>
          <th scope="col" class="text-end">Immatriculation</th>
          <th scope="col" class="text-end">Fabricant</th>
          <th scope="col" class="text-end">Modèle</th>
          <th scope="col" class="text-center">
            {{ $t("sale21") }}
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(repair, index) in repairs"
          :key="index++"
          :class="
            repair.totalPriceTTC < 0
              ? 'bg-warning-faint border-top border-bottom border-white'
              : repair.totalPriceTTC == repair.amountPaid
              ? 'bg-success-faint border-top border-bottom border-white'
              : ''
          "
        >
          <td>
            <input
              class="form-check-input"
              type="checkbox"
              :id="repair.reference"
              :value="repair.reference"
              v-model="selections"
            />
            {{ index }}
          </td>
          <td>
            <label :for="repair.reference">
              {{ repair.reference }}
            </label>
          </td>
          <td>
            <span>
              {{ repair.customerFullName }}
            </span>
          </td>
          <td>{{ repair.date }}</td>
          <td class="text-end">
            <span v-if="repair.totalPriceTTC" class="text-primary">
              {{ repair.totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span>
              {{ repair.vehicleRegistrationPlate }}
            </span>
          </td>
          <td class="text-end">
            <span>
              {{ repair.vehicleManufacturerName }}
            </span>
          </td>
          <td class="text-end">
            <span>
              {{ repair.vehicleModelName }}
            </span>
          </td>

          <td class="text-center">
            <span v-if="repair.userName">
              {{ repair.userName }}
            </span>
          </td>
          <td v-if="repair.validated">
            <span class="px-2 py-1 bg-success text-white rounded">
              {{ $t("sale22") }}
            </span>
          </td>

          <td v-if="!repair.validated">
            <span class="px-2 py-1 bg-warning rounded">
              {{ $t("sale23") }}
            </span>
          </td>
          <td>
            <button
              @click="
                $router.push({
                  name: 'repairs-details',
                  params: { reference: repair.reference },
                })
              "
              class="btn p-0 m-0"
            >
              <i class="bi bi-info-square p-0 m-0"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  // components: { SaleDataTableVue },
  data() {
    return {
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      selections: [],
    };
  },
  computed: {
    ...mapGetters("repair", {
      repairs: "getAll",
      totalQuantity: "getRepairsTotalQuantity",
      totalPriceTTC: "getRepairsTotalPriceTTC",
      totalPaid: "getRepairsTotalPaid",
      totalUnpaid: "getRepairsTotalUnpaid",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  created() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("repair/fetchRepairs");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("repair/search", value);
    },
    async refresh() {
      await this.$store.dispatch("repair/fetchRepairs");
      await this.$store.dispatch("customer/getAll");
    },

    async toFilter(data) {
      await this.$store.dispatch("repair/filter", data);
    },
    async printMany(data) {
      await this.$store.dispatch("repair/printManyBill", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
